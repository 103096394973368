import React, { useEffect, useState } from 'react';
import './Main.css';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

function Main({ setIssue, data, setData1 }) {
  const navigate = useNavigate();
  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  const handleChange = (e) => {
    const name = e.target.name;
    let value = e.target.value;
    if(name == 'issues'){
      value = value.replaceAll(' ', '_')
    }
    // console.log(data)
    setData1({ ...data, [name]: value });
  };

  const Support = () => {
    setIssue(data.issues);

    if (data.brand === '' || data.issues === '' || data.model === '' || data.caseID === '') {
      alert('Please provide details');
    } else {
      axios.post('https://blitzpath-gtc.el.r.appspot.com/submitData', data)
        .then(response => {
          // Handle the response from the Flask app if needed
          setData1({...data,questionResponse:[],sol:''})

          navigate(`/${data.issues}/0`);
        })
        .catch(error => {
          console.error('Error submitting data:', error);
        });
    }
  };

  return (
    <div className="mainContainer">
      <div className='container1'>
        <div className="form">
          <div className="form-group">
            <label htmlFor="brandSelect"><h5>Brand Name:</h5></label>
            <div className="drop">
              <Form.Select size="lg" name='brand' onChange={handleChange} className='form-control' id="brandSelect">
                <option defaultValue>Dell</option>
              </Form.Select>
            </div>
          </div>
          <div className="form-group">
            <label htmlFor="productSelect"><h5>Product group:</h5></label>
            <div className="drop">
              <Form.Select size="lg" name='product' onChange={handleChange} style={{backgroundColor: "#e5e5e5"}} id="productSelect">
                <option defaultValue>select</option>
                <option>Inspiron Desktop</option>
                <option>Inspiron Notebook</option>
                <option>Vostro Desktop</option>
                <option>Vostro Notebook</option>
                <option>Monitor</option>
                <option>peripherals</option>
              </Form.Select>
            </div>
          </div>
          <div className="form-group">
            <label htmlFor="modelInput"><h5>Model Number:</h5></label>
            <input type="text" className="form-control" id="modelInput" name='model' onChange={handleChange} placeholder="Enter Model No." />
          </div>
          <div className="form-group">
            <label htmlFor="caseInput"><h5>Case Id:</h5></label>
            <input type="text" className="form-control" id="caseInput" name='caseID' onChange={handleChange} placeholder="Enter Case Id" />
          </div>
          <div className="form-group">
            <label htmlFor="issuesSelect"><h5>Issue:</h5></label>
            <div className="drop">
              <Form.Select size="lg" name='issues' onChange={handleChange} style={{backgroundColor: "#e5e5e5"}} id="issuesSelect">
                <option defaultValue>select</option>
                <option>No power</option>
                <option>No power Desktop</option>
                <option>No post</option>
                <option>No Boot</option>
                <option>AC Adapter</option>
                <option>Slow Performance</option>
                <option>No Display</option>
                <option>No Audio</option>
                <option>Fingerprint Reader Issue</option>
                <option>Heating Issue</option>
                <option>Touchpad Issue</option>
                <option>Fan Issue</option>
                <option>Mouse Issue</option>
                <option>Battery Swollen Issue</option>
                <option>Battery Not Charging</option>
                <option>Battery Discharging Quickly</option>
                <option>Camera Issue</option>
                <option>Bluetooth Issue</option>
                <option>Touchscreen Issue</option>
                <option>Internal Keyboard Issue</option>
                <option>Bitlocker Issue</option>
                <option>Operating System Issue</option>
                <option>USB Port Troubleshooting</option>
                {/* <option>Touchpad not working</option>
                <option>Black screen issue</option>
                <option>Overheating</option> */}
              </Form.Select>
            </div>
          </div>
          <div className="form-group">
           <div className="buttons">
            <Button
  style={{ backgroundColor:"#1f2325", borderColor:"#1f2325", color: "white" }}
  onMouseOver={(e) => {
    e.target.style.backgroundColor = "#ced4da";
    e.target.style.color = "#1f2325";
  }}
  onMouseOut={(e) => {
    e.target.style.backgroundColor = "#1f2325";
    e.target.style.color = "white";
  }}
  onClick={Support}
>
  Go With Premium Support
</Button>
            <Button
  style={{ backgroundColor: "#1f2325", borderColor: "#1f2325", color: "white" }}
  onMouseOver={(e) => {
    e.target.style.backgroundColor = "#ced4da";
    e.target.style.color = "#1f2325";
  }}
  onMouseOut={(e) => {
    e.target.style.backgroundColor = "#1f2325";
    e.target.style.color = "white";
  }}
  onClick={Support}
>
Go With Basic Support
</Button>
            </div>
          </div>
        </div>
      </div>

      <section></section>
    </div>
  );
}

export default Main;
