import React, { useContext, useEffect } from "react";
import "./Header.css";
import { Link } from "react-router-dom";
import logo from "../../../image/Blitzpath logo- Copy.png";
import { UserContext } from "../../../App";

function Header({ display }) {
  const { isAdmin, setIsAdmin, isSuperAdmin, setIsSuperAdmin } = useContext(UserContext);

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem("user"));
    if (user && user.role === "Admin") {
      setIsAdmin(true);
    } else if (user && user.role === "superAdmin") {
      setIsSuperAdmin(true);
    }
  }, []);

  return (
    <header className="header" style={{ display: display }}>
      <div className="container">
        <div className="title1">
          <div className="logo">
            <img src={logo} alt="" />
          </div>
          <div className="title">
            <Link to="/home">
              <h3
                style={{ transition: "transform 0.6s" }}
                onMouseOver={(e) => (e.target.style.transform = "scale(1.06)")}
                onMouseOut={(e) => (e.target.style.transform = "scale(1)")}
              >
                TechBook
              </h3>
            </Link>
          </div>
        </div>

        <div className="list">
          <ul>
            {isAdmin || isSuperAdmin ? (
              <li>
                <Link
                  to="/dashboard"
                  className="dashboard-link"
                >
                  Dashboard
                </Link>
              </li>
            ) : null}
            <li>
              <Link to="#" className="focus-areas-link">
                Focus Areas
              </Link>
            </li>
            <li>
              <Link to="/Articles" className="ready_reckoner">
                Ready Reckoner
              </Link>
            </li>
            <li>
              <Link to="/probing" className="probing">
                Probing
              </Link>
            </li>
            {isSuperAdmin ? (
              <li>
                <Link
                  to="/request_sign_up"
                  className="dashboard-link"
                >
                  Sign Up Request
                </Link>
              </li>
            ) : null}
          </ul>
        </div>
      </div>
    </header>
  );
}

export default Header;
