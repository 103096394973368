import React from 'react';
import './Article.css';
import { Link } from 'react-router-dom';

const Article = () => {
  return (
    <div className="main_container2">
      <div className="container2">
        <div className="table_css">
          <div className="Column_css">
            <h3 className="heading_column">Basic Troubleshooting Guidelines</h3>
            <div>
              <a href="https://www.dell.com/support/kbdoc/en-in/000124389/how-to-troubleshoot-power-issues-on-a-dell-laptop" target="_blank" className='link_css'>
                No power desktop
              </a>
            </div>
            <div>
              <a href="https://www.dell.com/support/contents/en-in/article/product-support/self-support-knowledgebase/fix-common-issues/no-power" target="_blank" className='link_css'>
                No power laptop
              </a>
            </div>
            <div>
              <a href="https://www.dell.com/support/kbdoc/en-in/000141584/how-to-troubleshoot-a-no-post-issue-on-a-dell-notebook-pc" target="_blank" className='link_css'>
                No post
              </a>
            </div>
            <div>
              <a href="https://www.dell.com/support/kbdoc/en-in/000125609/resolve-no-power-no-post-no-boot-or-no-video-issues-with-your-dell-computer" target="_blank" className='link_css'>
                Resolve No Power, No POST, No Boot or No Video issues with your Dell Computer
              </a>
            </div>
            <div>
              <a href="https://www.dell.com/support/kbdoc/en-in/000134946/how-to-troubleshoot-display-or-video-issues-on-dell-laptop-lcd-panel" target="_blank" className='link_css'>
                No video on laptop
              </a>
            </div>
            <div>
              <a href="https://www.dell.com/support/kbdoc/en-in/000125125/how-to-troubleshoot-ac-adapter-issues?lang=en" target="_blank" className='link_css'>
                AC Adapter
              </a>
            </div>
            <div>
              <a href="https://www.dell.com/support/kbdoc/en-in/000178928/how-to-troubleshoot-slow-performance-issues?lang=en" target="_blank" className='link_css'>
                Slow Performance
              </a>
            </div>
            <div>
              <a href="https://www.dell.com/support/kbdoc/en-in/000179019/how-to-troubleshoot-audio-or-sound-playback-issues?lang=en" target="_blank" className='link_css'>
                No Audio
              </a>
            </div>
            <div>
              <a href="https://www.dell.com/support/kbdoc/en-in/000145478/how-to-troubleshoot-and-resolve-fingerprint-reader-and-security-software-issues?lang=en" target="_blank" className='link_css'>
                Fingerprint Reader Issue
              </a>
            </div>
            <div>
              <a href="https://www.dell.com/support/kbdoc/en-in/000130867/how-to-troubleshoot-a-overheating-shutdown-or-thermal-issue-on-a-dell-pc?lang=en" target="_blank" className='link_css'>
                Heating Issue
              </a>
            </div>
            <div>
              <a href="https://www.dell.com/support/kbdoc/en-in/000123353/touchpad-usage-and-troubleshooting-guide?lang=en" target="_blank" className='link_css'>
                Touchpad Issue
              </a>
            </div>
            <div>
              <a href="https://www.dell.com/support/kbdoc/en-in/000179087/how-to-troubleshoot-fan-issues?lang=en" target="_blank" className='link_css'>
                Fan Issue
              </a>
            </div>
            <div>
              <a href="https://www.dell.com/support/kbdoc/en-in/000124626/mouse-usage-and-troubleshooting-guide?lang=en" target="_blank" className='link_css'>
                Mouse Issue
              </a>
            </div>
            <div>
              <a href="https://www.dell.com/support/kbdoc/en-in/000123069/how-to-troubleshoot-dell-laptop-battery-issues?lang=en" target="_blank" className='link_css'>
                Battery Swollen Issue
              </a>
            </div>
            <div>
              <a href="https://www.dell.com/support/kbdoc/en-in/000144108/laptop-battery-does-not-charge-beyond-55-on-certain-dell-pcs?lang=en" target="_blank" className='link_css'>
                Battery Not Charging Issue
              </a>
            </div>
            <div>
              <a href="https://www.dell.com/support/kbdoc/en-in/000124356/using-troubleshooting-and-resolving-driver-issues-on-your-dell-webcam?lang=en" target="_blank" className='link_css'>
                Camera issue
              </a>
            </div>
            <div>
              <a href="https://www.dell.com/support/contents/en-in/article/product-support/self-support-knowledgebase/networking-wifi-and-bluetooth/bluetooth" target="_blank" className='link_css'>
                Bluetooth Issue
              </a>
            </div>
            <div>
              <a href="https://www.dell.com/support/kbdoc/en-in/000132121/how-to-troubleshoot-touchscreen-touch-panel-issues?lang=en" target="_blank" className='link_css'>
                Touchscreen Issue
              </a>
            </div>
            <div>
              <a href="https://www.dell.com/support/kbdoc/en-in/000131432/keyboard-usage-and-troubleshooting-guide?lang=en" target="_blank" className='link_css'>
                Internal Keyboard Issue
              </a>
            </div>
          </div>

          <div className="Column_css">
            <h3 className="heading_column">Diagnostics</h3>
            <div>
              <a href="https://www.dell.com/support/kbdoc/en-in/000181163/how-to-enter-the-built-in-diagnostics-32-bit-diagnostics-supportassist-epsa-epsa-and-psa" target="_blank" className='link_css'>Epsa diagnostic</a>
            </div>
            <div>
              <a href="https://www.dell.com/support/kbdoc/en-in/000186628/how-to-troubleshoot-desktop-motherboard-issues-using-m-bist" target="_blank" className='link_css'>Motherboard M-Bist test</a>
            </div>
            <div>
              <a href="https://www.dell.com/support/kbdoc/en-in/000134936/how-to-run-the-lcd-built-in-self-test-on-a-dell-laptop" target="_blank" className='link_css'>LCD Bist test</a>
            </div>
            <div>
              <a href="https://www.dell.com/support/kbdoc/en-in/000125185/how-to-troubleshoot-the-power-supply-unit-psu-of-a-dell-desktop-computer" target="_blank" className='link_css'>PSU test (desktop)</a>
            </div>
            <div>
              <a href="https://www.dell.com/support/kbdoc/en-in/000125179/how-to-run-a-power-supply-unit-self-test-on-a-dell-desktop-or-all-in-one-computer" target="_blank" className='link_css'>PSU test for (AIO)</a>
            </div>
            <div>
              <a href="https://www.dell.com/support/contents/en-in/videos/videoplayer/load-bios-to-defaults/6176626462001" target="_blank" className='link_css'>Load BIOS to default</a>
            </div>
            <div>
              <a href="https://youtu.be/sPWY4y4RlBo" target="_blank" className='link_css'>HDD detected in BIOS</a>
            </div>
            <div>
              <a href="https://www.dell.com/support/kbdoc/en-us/000125880/how-to-reset-real-time-clock-rtc-to-recover-your-dell-portable-system" target="_blank" className='link_css'>RTC</a>
            </div>
            <div>
              <a href="https://www.dell.com/support/kbdoc/en-in/000187496/customer-replaceable-unit-program" target="_blank" className='link_css'>CRU</a>
            </div>
            <div>
              <a href="https://www.dell.com/support/kbdoc/en-uy/000139016/how-to-perform-hard-reset#:~:text=Disconnect%20all%20devices%20such%20as,Turn%20on%20the%20computer." target="_blank" className='link_css'>Hard reset</a>
            </div>
            <div>
              <a href="https://www.dell.com/support/kbdoc/en-in/000124397/how-to-check-battery-health-status-on-dell-laptops" target="_blank" className='link_css'>AC Adapter & battery status in BIOS</a>
            </div>
            <div>
              <a href="https://youtu.be/M7hbokr3CPc" target="_blank" className='link_css'>Update BIOS</a>
            </div>
            <div>
              <a href="https://www.dell.com/support/kbdoc/en-us/000178177/how-to-use-windows-reliability-monitor-to-identify-software-issues" target="_blank" className='link_css'>Reliability monitor</a>
            </div>
          </div>

          <div className="Column_css">
            <h3 className="heading_column">Contact Information</h3>
            <div>
            <a href="https://www.dell.com/support/home/en-us" target="_blank" className='link_css'>Dell Support</a>
            </div>
            <div>
              <a className='link_css'>Second Link</a>
            </div>
            <div>
              <a className='link_css'>Third Link</a>
            </div>
            <div>
              <a className='link_css'>Fourth Link</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Article;
