import "bootstrap/dist/css/bootstrap.min.css";
import Home from "./components/Home/Home";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { createContext, useState, useEffect } from "react";
import Header from "./components/Home/Header/Header";
import Second from "./components/Second/Second";
import Login from "./components/Login/login";
import Dashboard from "./components/Dashboard/Dashboard";
import ItemList from "./components/Dashboard/ItemList";
import Article from "./components/Home/Articles/Article";
import SignUp from "./components/SignUp/SignUp";
import RequestSignUp from "./components/Home/SignUpRequest/RequestSignUp";
import Probing from "./components/Home/Probing/Probing"; // Import the Probing component

const UserContext = createContext();

function App() {
  const [isAdmin, setIsAdmin] = useState(false);
  const [isSuperAdmin, setIsSuperAdmin] = useState(false);
  const [question, setQuestion] = useState();

  useEffect(() => {
    fetchData();
  }, []);

  const handleChange = (value) => {
    setDisplay(value);
  };
  const handleAdminChange = (value) => {
    setIsAdmin(value);
  };
  const handleSuperAdminChange = (value) => {
    setIsSuperAdmin(value);
  };

  const fetchData = async () => {
    try {
      const response = await fetch("https://blitzpath-gtc.el.r.appspot.com/getData");
      if (response.ok) {
        const data = await response.json();
        setQuestion(data[0]);
      } else {
        console.error("Failed to fetch questions data");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const [issue, setIssue] = useState("");
  const [userResponse, setUserResponse] = useState([]);
  const [display, setDisplay] = useState("none");
  const [data1, setData1] = useState({
    brand: "Dell",
    model: "",
    issues: "",
    product: "",
    case: "",
    questionResponse: [],
    sol: ""
  });

  return (
    <UserContext.Provider
      value={{
        isAdmin,
        setIsAdmin,
        isSuperAdmin,
        setIsSuperAdmin
      }}
    >
      <>
        <Router>
          <Header display={display} />
          <Routes>
            <Route path="/Home" element={<Home setIssue={setIssue} data={data1} setDisplay={setDisplay} setData1={setData1} />} />
            <Route path="/" element={<Login onValueChange={handleChange} OnAdminChange={handleAdminChange} onSuperAdminChange={handleSuperAdminChange} setDisplay={setDisplay} />} />
            <Route path="/dashboard" element={<Dashboard setDisplay={setDisplay} />} />
            <Route path="/dashboard/details/:id" element={<ItemList setDisplay={setDisplay} />} />
            <Route path="/SignUp" element={<SignUp />} />
            <Route path="/request_sign_up" element={<RequestSignUp setDisplay={setDisplay} />} />
            <Route path="/probing" element={<Probing />} /> {/* Route for the Probing page */}
            <Route path="/:issue/:id" element={<Second data={data1} setData1={setData1} issues={issue} question={question} />} />
            <Route path="/Articles" element={<Article />} />
          </Routes>
        </Router>
      </>
    </UserContext.Provider>
  );
}

export { UserContext };
export default App;
